window.addEventListener("load", function () {
  const loader = document.getElementById("pageLoader");
  loader.style.opacity = "0";
  setTimeout(() => {
    loader.style.display = "none";
  }, 500);
});

window.addEventListener("beforeunload", function () {
  const loader = document.getElementById("pageLoader");
  loader.style.display = "flex";
  loader.style.opacity = "1";
});

document.addEventListener("DOMContentLoaded", () => {
  const nav = document.getElementById("stickyNav");
  const navPosition = nav.offsetTop;
  let lastScrollY = window.scrollY;

  function handleScroll() {
    const currentScrollY = window.scrollY;

    if (currentScrollY > navPosition) {
      if (currentScrollY > lastScrollY) {
        nav.classList.add("hide");
        nav.classList.remove("show");
      } else {
        nav.classList.remove("hide");
        nav.classList.add("show");
      }
    } else {
      nav.classList.remove("hide", "show");
    }

    lastScrollY = currentScrollY;
  }

  window.addEventListener(
    "scroll",
    () => {
      requestAnimationFrame(handleScroll);
    },
    {
      passive: true,
    }
  );
});
