console.error = () => {};

import PhotoSwipeLightbox from "photoswipe/lightbox";

document.addEventListener("DOMContentLoaded", () => {
  const lightbox = new PhotoSwipeLightbox({
    gallery: "#my-gallery",
    children: "a",
    mouseMovePan: true,
    pswpModule: () => import("photoswipe"),

    bgOpacity: 1,
    initialZoomLevel: "fit",
    allowUserZoom: false,
    closeButton: false,
    imageClickAction: false,
    tapAction: false,
    doubleTapAction: false,
    pinchToClose: false,
    spacing: 0,

    padding: {
      top: 50,
      bottom: 40,
      left: 0,
      right: 0,
    },
    sortElements: (elements) => {
      return Array.from(elements).sort((a, b) => {
        return parseInt(a.dataset.order) - parseInt(b.dataset.order);
      });
    },

    arrowPrevSVG:
      '<svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1L1 13L13 25" stroke="#343434" stroke-width="1.3" stroke-linecap="round"/> </svg>',
    arrowNextSVG:
      '<svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 1L18 13L6 25" stroke="#343434" stroke-width="1.3" stroke-linecap="round"/> </svg>',
  });

  const initialItemCount = document.querySelectorAll("#my-gallery a").length;

  const addImagesToLightbox = () => {
    const pswp = lightbox.pswp; // Access the active PhotoSwipe instance
    if (!pswp) {
      console.error("PhotoSwipe instance is not initialized yet.");
      return;
    }

    if (!window.imagesData || window.imagesData.length === 0) {
      console.error("No images available in window.imagesData.");
      return;
    }

    const remainingImages = window.imagesData.slice(initialItemCount);

    const currentItems = pswp.options.dataSource.items || [];

    remainingImages.forEach((imageData, index) => {
      const img = new Image();
      img.src = imageData.src;

      img.onload = function () {
        currentItems.push({
          src: img.src,
          alt: imageData.alt || "",
          width: img.naturalWidth,
          height: img.naturalHeight,
          id: imageData.id,
        });

        // If this is the last image being added, refresh the UI
        if (index === window.imagesData.length - 1) {
          pswp.invalidateCurrItems();
          pswp.updateSize(true);
          console.log("All images added to PhotoSwipe.");
        }
      };

      img.onerror = function () {
        console.error(`Failed to load image: ${img.src}`);
      };
    });
  };

  const handleURLParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const imageId = urlParams.get("id");
    console.log(1);
    if (imageId) {
      const imageElement = document.querySelector(
        `[data-image-id="${imageId}"]`
      );
      if (imageElement) {
        const index = Array.from(
          document.querySelectorAll("#my-gallery a")
        ).indexOf(imageElement);
        if (index !== -1) {
          lightbox.loadAndOpen(index);
        }
      }
    }
  };
  lightbox.on("change", () => {
    const currentSlide = lightbox.pswp.currSlide;
    const imageId =
      currentSlide?.data?.element?.dataset?.imageId ||
      lightbox.pswp.currSlide.data.id;

    const newUrl = new URL(window.location);

    // Remove any existing 'imageDetails.php' from the pathname
    newUrl.pathname = newUrl.pathname.replace(/\/imageDetails\.php/, "");

    newUrl.searchParams.set("id", imageId);
    window.history.replaceState({}, "", "imageDetails.php" + newUrl.search);
  });

  // Clean up URL when gallery closes
  lightbox.on("close", () => {
    const newUrl = new URL(window.location);
    newUrl.pathname = newUrl.pathname.replace(/\/imageDetails\.php/, "");
    newUrl.search = "";
    window.history.replaceState({}, "", newUrl.toString());
  });

  lightbox.on("uiRegister", function () {
    lightbox.pswp.ui.registerElement({
      name: "custom-title-counter",
      order: 5,
      isButton: false,
      appendTo: "bar",
      html: `
                  <div class="flex items-center gap-4">
                      <h2 class="font-secondary text-[11px] leading-[16.5px] text-primary dark:text-white tracking-[.105em]">Elizabeth And Ryan</h2>
                      <div class="w-[2px] h-[25px] bg-primary opacity-15 dark:bg-white self-start"></div>
                      <span class="font-secondary text-[11px] leading-[16.5px] text-primary opacity-60 dark:text-white"></span>
                  </div>
              `,
      onInit: (el, pswp) => {
        const counter = el.querySelector("span");
        pswp.on("change", () => {
          counter.textContent = `Photo ${pswp.currIndex + 1} of ${
            window.imagesData.length - 3
          }`;
        });
      },
    });

    // Download button
    lightbox.pswp.ui.registerElement({
      name: "custom-download",
      order: 8,
      isButton: true,
      html: `  <svg class="w-6 h-6 dark:opacity-80">
            <use href="../images/sprite.svg#icon-download" />
        </svg>`,
      className:
        "!mr-[14px] hover:bg-white/10 group p-2 size-10 md:size-[56px] transition-colors grid place-content-center",
      onInit: (el, pswp) => {
        pswp.on("change", () => {
          el.href = pswp.currSlide.data.src;
        });
      },
    });

    // Like button
    lightbox.pswp.ui.registerElement({
      name: "custom-like",
      order: 9,
      isButton: true,
      html: ` <svg class="w-6 h-6 dark:opacity-80">
                      <use href="../images/sprite.svg#icon-heart" />
        </svg>`,
      className:
        "!mr-[14px] hover:bg-white/10 group p-2 size-10 md:size-[56px] transition-colors grid place-content-center pswp__button--like",
      onInit: (el, pswp) => {
        // Set initial image ID when modal opens
        const setImageId = () => {
          const currentSlide = pswp.currSlide.data;
          const imageId = currentSlide?.element?.dataset?.imageId || "";
          el.setAttribute("data-image-id", imageId);
        };

        // Set initial state

        // Update when slide changes
        pswp.on("change", () => {
          setImageId();

          const currentSlide = pswp.currSlide.data;
          const imageId = currentSlide?.element?.dataset?.imageId;
          const thumbButton = document.querySelector(
            `[data-image-id="${imageId}"] .like-button`
          );
        });

        // Handle click events
        el.addEventListener("click", () => {
          const imageId = el.getAttribute("data-image-id");
          const thumbButton = document.querySelector(
            `[data-image-id="${imageId}"] .like-button`
          );
        });
      },
    });
    // Share button
    lightbox.pswp.ui.registerElement({
      name: "custom-share",
      order: 10,
      isButton: true,
      html: `   <svg class="w-6 h-6 dark:opacity-80">
            <use xlink:href="../images/sprite.svg#icon-share"></use>
        </svg>`,
      className:
        "!mr-[14px] hover:bg-white/10 group p-2 size-10 md:size-[56px] transition-colors grid place-content-center",
    });
    lightbox.pswp.ui.registerElement({
      name: "custom-play",
      order: 10,
      isButton: true,
      html: `   <svg class="w-6 h-6 dark:opacity-80">
            <use href="../images/sprite.svg#icon-play" />
        </svg>`,
      className:
        "!mr-[14px] hover:bg-white/10 group p-2 size-10 md:size-[56px] transition-colors grid place-content-center",
      onInit: (el, pswp) => {
        // Set initial image ID when modal opens
        const setImageId = () => {
          const currentSlide = pswp.currSlide.data;
          const imageId = currentSlide?.element?.dataset?.imageId;
          el.setAttribute("data-image-id", imageId);
        };

        // Update when slide changes
        pswp.on("change", () => {
          setImageId();

          const currentSlide = pswp.currSlide.data;
          const imageId = currentSlide?.element?.dataset?.imageId;
          const thumbButton = document.querySelector(
            `[data-image-id="${imageId}"] .like-button`
          );
        });

        // Handle click events
        el.addEventListener("click", () => {
          const imageId = el.getAttribute("data-image-id");
          const pswpElement = document.querySelector(".pswp");

          // Add transition to opacity
          // pswpElement.style.transition = "opacity 0.3s ease-in-out";
          // pswpElement.style.opacity = "0";

          openSlideshowAtId(
            pswp.currSlide.data.id ? pswp.currSlide.data.id - 1 : imageId - 1
          );
          // lightbox.pswp.close();
        });
      },
    });

    // Close button
    lightbox.pswp.ui.registerElement({
      name: "custom-close",
      order: 11,
      isButton: true,
      html: `<img src="../images/main/close.svg" alt="close icon" class="group-hover:opacity-50 transition-all duration-300"/>`,
      className:
        "hover:bg-white/10 group close-btn size-10 md:size-[56px] transition-colors grid place-content-center",
      onClick: () => {
        lightbox.pswp.close();
      },
    });
  });

  handleURLParams();
  lightbox.init();
  lightbox.on("afterInit", addImagesToLightbox);

  // Listen for the custom event when images data is loaded
  window.addEventListener("imagesLoaded", () => {
    addImagesToLightbox();
  });

  // If images data is already available, preload them
  if (window.imagesData) {
    addImagesToLightbox();
  }
  window.lightbox = lightbox;
});
