// console.error = () => {};
// console.warn = () => {};
// console.log = () => {};

class Slideshow {
  constructor(images) {
    this.images = images;
    this.currentIndex = 0;
    this.interval = null;
    this.isPlaying = false;
    this.duration = 3000;

    this.modal = document.getElementById("imageModal-auto");
    this.image = document.getElementById("slideshowImage");
    this.counter = document.getElementById("slideshowCounter");
    this.toggleButton = document.getElementById("slideshowToggle");

    // Bind methods
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.next = this.next.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
    this.openAtId = this.openAtId.bind(this);
    this.syncWithGallery = this.syncWithGallery.bind(this);
    this.slideshowChangeListener = this.updatePhotoSwipeIndex.bind(this);
    this.openGalleryAtCurrentImage = this.openGalleryAtCurrentImage.bind(this);

    // Initialize event listeners
    this.initializeEventListeners();
  }

  syncWithGallery(newIndex) {
    this.currentIndex = newIndex;
    this.update();
  }

  initializeEventListeners() {
    // Close on escape
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") this.close();
    });

    // Close on outside click
    this.modal?.addEventListener("click", (e) => {
      if (e.target === this.modal) this.close();
    });
  }

  start(id) {
    if (!this.modal || this.isPlaying) return;

    // Show modal
    this.modal.classList.remove("hidden");
    requestAnimationFrame(() => this.modal.classList.remove("opacity-0"));
    document.body.style.overflow = "hidden";

    // Reset to first image
    this.currentIndex = id;
    this.update();

    // Start playing
    this.isPlaying = true;
    this.next = this.next.bind(this);
    this.timer = setInterval(this.next, this.duration);
    this.updateToggleButton();

    // Add event listener for image changes
    if (this.timer) {
      this.timer.addEventListener("tick", this.slideshowChangeListener);
    }
  }

  updatePhotoSwipeIndex() {
    if (window.lightbox && window.lightbox.pswp) {
      window.lightbox.pswp.goTo(this.currentIndex);
    }
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    // Remove the event listener from the correct object
    if (this.slideshowChangeListener) {
      if (window.lightbox && window.lightbox.pswp) {
        window.lightbox.pswp.off("slide", this.slideshowChangeListener); // Correctly remove the listener
      }
    }

    this.isPlaying = false;
    this.updateToggleButton();
  }

  toggle() {
    if (this.isPlaying) {
      this.stop();
    } else {
      this.interval = setInterval(this.next, this.duration);
      this.isPlaying = true;
      this.updateToggleButton();
    }
  }
  openGalleryAtCurrentImage(id) {
    console.log(id, this.images.length, id >= 0 && id < this.images.length);
    if (id >= 0 && id < this.images.length) {
      const imageElement = document.querySelector(
        `[data-image-id="${this.images[id].id}"]`
      );

      if (imageElement) {
        const index = Array.from(
          document.querySelectorAll("#my-gallery a")
        ).indexOf(imageElement);
        window.lightbox.loadAndOpen(index);
        console.log(index);
      }
      // Start the slideshow
      this.start(id);
    }
  }
  next() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.update();

    // Manually trigger the PhotoSwipe index update
    this.updatePhotoSwipeIndex();
  }

  update() {
    if (!this.image || !this.counter) return;

    const currentImage = this.images[this.currentIndex];
    this.image.src = currentImage.src;
    this.image.alt = currentImage.alt;

    this.counter.textContent = `Photo ${this.currentIndex + 1} of ${
      this.images.length
    }`;
  }

  close() {
    if (!this.modal) return;

    this.stop();
    this.modal.classList.add("opacity-0");

    setTimeout(() => {
      this.modal.classList.add("hidden");
      document.body.style.overflow = "auto";
    }, 300);
  }
  setOnCloseCallback(callback) {
    this.onCloseCallback = callback;
  }
  updateToggleButton() {
    if (!this.toggleButton) return;

    const buttonContent = this.isPlaying
      ? `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="stroke-white">
            <path d="M8 5v14M17 5v14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="text-sm uppercase tracking-wider">PAUSE</span>
    `
      : `
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="transition-all duration-200 stroke-primary hover:opacity-50 dark:stroke-white dark:hover:stroke-white/50">
            <path d="M8.8001 4.37867C7.225 3.46923 6.4366 3.01465 5.7903 3.08257C5.2266 3.14182 4.7149 3.43768 4.3818 3.89624C3.9999 4.4218 3.9999 5.33066 3.9999 7.14829L3.9999 16.8513C3.9999 18.6689 3.9999 19.5776 4.3818 20.1032C4.7149 20.5617 5.2266 20.8577 5.7903 20.917C6.4366 20.9849 7.225 20.5305 8.8001 19.621L17.2001 14.7713C18.7753 13.8619 19.5628 13.4069 19.8271 12.8133C20.0576 12.2955 20.0576 11.7038 19.8271 11.186C19.5629 10.5926 18.7754 10.1379 17.2015 9.22922L8.8001 4.37867Z" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="text-sm uppercase tracking-wider">PLAY</span>
    `;

    this.toggleButton.innerHTML = buttonContent;
    this.toggleButton.classList.toggle("playing", this.isPlaying);
  }

  openAtId(id) {
    if (id >= 0 && id < this.images.length) {
      this.start(id);
    } else {
      console.error(
        `Invalid image index: ${id}. Must be between 0 and ${
          this.images.length - 1
        }`
      );
    }
  }
}

document.addEventListener("DOMContentLoaded", function () {
  if (!window.imagesData || !Array.isArray(window.imagesData)) {
    console.error("Images data is not defined or is invalid.");
    return;
  }

  const slideshow = new Slideshow(window.imagesData);

  window.openGalleryAtCurrentImage = (id) => {
    slideshow.openGalleryAtCurrentImage(id);
  };
  // Make methods globally available
  window.startAutoSlideshow = slideshow.start;
  window.toggleSlideshow = slideshow.toggle;
  window.closeSlideshow = slideshow.close;
  window.openSlideshowAtId = slideshow.openAtId;
});

export default Slideshow;
