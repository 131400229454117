import Macy from "macy";

let macyInstance;

function initMacy() {
  if (macyInstance) {
    macyInstance.reInit();
  } else {
    macyInstance = Macy({
      container: "#my-gallery",
      trueOrder: false,
      waitForImages: false,
      margin: 5,
      columns: 5,
      breakAt: {
        1537: {
          margin: 5,
          columns: 4,
        },
        1033: {
          margin: 5,
          columns: 3,
        },
        834: {
          margin: 5,
          columns: 2,
        },
        // 520: {
        //     margin: 5,
        //     columns: 2
        // },
        // 330: {
        //     margin: 5,
        //     columns: 2
        // }
      },
    });
  }

  macyInstance.recalculate(true);
}

// Initialize on DOM ready
document.addEventListener("DOMContentLoaded", function () {
  initMacy();

  // Force recalculation after a short delay
  setTimeout(initMacy, 100);

  // Recalculate when all resources are loaded
  window.addEventListener("load", initMacy);

  // Recalculate on resize
  window.addEventListener("resize", function () {
    macyInstance.recalculate(true);
  });

  // Recalculate on image load
  macyInstance.runOnImageLoad(function () {
    macyInstance.recalculate(true);
  });

  // Ensure layout is correct after all images load
  macyInstance.on(macyInstance.constants.EVENT_IMAGE_COMPLETE, function () {
    macyInstance.recalculate(true);
  });
});

// Optional: Add a global recalculate function
window.recalculateMacy = function () {
  if (macyInstance) {
    macyInstance.recalculate(true);
  }
};
