function initNewModal() {
  const modal = document.getElementById("newModal");
  const shareButton = document.querySelector(".md\\:order-first");
  const emailInput = document.getElementById("email-input");

  if (!modal) {
    console.error("Modal element not found");
    return;
  }

  if (!shareButton) {
    console.error("Share button not found");
    return;
  }

  function openNewModal() {
    modal.classList.remove("hidden");
    // Use setTimeout to ensure the transition happens after display is set
    requestAnimationFrame(() => {
      modal.classList.remove("opacity-0");
    });
    document.body.style.overflow = "hidden";

    // Focus the email input after modal is visible
    setTimeout(() => {
      emailInput?.focus();
    }, 300);
  }

  function closeNewModal() {
    modal.classList.add("opacity-0");
    setTimeout(() => {
      modal.classList.add("hidden");
      document.body.style.overflow = "auto";
      if (emailInput) {
        emailInput.value = "";
      }
    }, 300);
  }

  // Add click event listener to share button
  shareButton.addEventListener("click", (e) => {
    e.preventDefault();
    openNewModal();
  });

  // Close on click outside of modal content
  modal.addEventListener("click", (e) => {
    if (e.target === modal) {
      closeNewModal();
    }
  });

  // Close on escape key
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && !modal.classList.contains("hidden")) {
      closeNewModal();
    }
  });

  // Add close button event listener
  const closeButton = modal.querySelector('button[onclick="closeNewModal()"]');
  if (closeButton) {
    closeButton.removeAttribute("onclick");
    closeButton.addEventListener("click", closeNewModal);
  }

  // Make functions globally available
  window.openNewModal = openNewModal;
  window.closeNewModal = closeNewModal;
}

// Initialize when document is ready
document.addEventListener("DOMContentLoaded", initNewModal);

// Also initialize immediately if the DOM is already loaded
if (
  document.readyState === "complete" ||
  document.readyState === "interactive"
) {
  initNewModal();
}
